import { useQuery } from "@tanstack/react-query";
import { useBackend, useRegion } from "./useRegion";
import { backend } from "src/services/backend";

import { CsatResp, ExpiringContractCtMap, ExpiringContractCtMapIndeterminate, InvoiceTotals, Profitability, QipMap, SalesforceIdMap, SalesforceOpportunityResponse, SnBases, SnBasesIndeterminate } from "src/types";

export function useCsat(accountSysIds:string, startDate:string, metricSysIds:string){
    accountSysIds = accountSysIds.split(",").sort().join(",")

    const backend = useBackend(); 
    const { status, data, error } = useQuery<CsatResp[], Error>({
        queryFn: () => backend
            .get<{ result: CsatResp[] }>("/getCsat" + 
                `?accountSysIds=${accountSysIds}` + 
                `&startDateYYYY-MM-DD=${startDate}` + 
                `&metricSysIds=${metricSysIds}` 
            )
            .then(res => res.data.result),
        queryKey: ['csat', accountSysIds, startDate, metricSysIds],
        staleTime: 1000 * 60 * 10, 
        enabled: accountSysIds.length > 0 && metricSysIds.length > 0
    });
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}


export function useQipMap(accountSysIds: string){
    accountSysIds = accountSysIds.split(",").sort().join(",")

    const backend = useBackend(); 
    const { status, data, error } = useQuery<QipMap, Error>({
        queryFn: () => backend
            .get<{ result: QipMap }>("/getQip" + 
                `?accountSysIds=${accountSysIds}`
            )
            .then(res => res.data.result),
        queryKey: ['qipMap', accountSysIds],
        staleTime: 1000 * 60 * 10, 
        enabled: accountSysIds.length > 0
    });
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}

export function useSalesforceIdMap(accountSysIds: string){
    accountSysIds = accountSysIds.split(",").sort().join(",")
    const backend = useBackend(); 
    const { status, data, error } = useQuery<SalesforceIdMap, Error>({
        queryFn: () => backend
            .get<{ result: SalesforceIdMap }>("/getSalesforceIds" + 
                `?accountSysIds=${accountSysIds}`
            )
            .then(res => res.data.result),
        queryKey: ['SalesforceIdMap', accountSysIds],
        staleTime: 1000 * 60 * 10, 
        enabled: accountSysIds.length > 0
    });
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}

export function useProfitability(accountSysIds:string, startDate:string){
    accountSysIds = accountSysIds.split(",").sort().join(",")
    const backend = useBackend();
    const { status, data, error } = useQuery<Profitability, Error>({
        queryFn: () => backend
            .get<{ result: Profitability }>("/getProfitability" + 
                `?accountSysIds=${accountSysIds}` + 
                `&startAtYYYY-MM-DD=${startDate}`
            )
            .then(res => res.data.result),
        queryKey: ['profitability', accountSysIds, startDate],
        staleTime: 1000 * 60 * 10, 
        enabled: accountSysIds.length > 0
    });
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}


export function useContractsExpiringCtMap(accountSysIds: string, daysThreshold: number): ExpiringContractCtMapIndeterminate{
    accountSysIds = accountSysIds.split(",").sort().join(",")
    const backend = useBackend();
    const { status, data, error } = useQuery<ExpiringContractCtMap, Error>({
        queryFn: () => backend
            .get<{ result: ExpiringContractCtMap }>("/getContractsExpiringCt" + 
                `?accountSysIds=${accountSysIds}` + 
                `&daysThreshold=${daysThreshold}`
            )
            .then(res => res.data.result),
        queryKey: ['ContractsExpiringCtMap', accountSysIds, daysThreshold],
        staleTime: 1000 * 60 * 10, 
        enabled: accountSysIds.length > 0
    });
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}


export function useInvoiceTotals(accountSysIds: string, startDate: string, endDate:string){
    accountSysIds = accountSysIds.split(",").sort().join(",")
    const backend = useBackend(); 
    const { status, data, error } = useQuery<InvoiceTotals, Error>({
        queryFn: () => backend
            .get<{ result: InvoiceTotals }>("/getInvoiceTotals" + 
                `?accountSysIds=${accountSysIds}` + 
                `&startDateYYYY-MM-DD=${startDate}` + 
                `&endDateYYYY-MM-DD=${endDate}`
            )
            .then(res => res.data.result),
        queryKey: ['invoiceTotals', accountSysIds, startDate, endDate],
        staleTime: 1000 * 60 * 10, 
        enabled: accountSysIds.length > 0
    });
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}

export function useOpportunities(userSalesforceId: string | null){
    const { status, data, error } = useQuery<SalesforceOpportunityResponse, Error>({
        queryFn: () => backend
            .get<SalesforceOpportunityResponse>("/sf/getOpportunities" + 
                `?usersfid=${userSalesforceId}`
            )
            .then(res => res.data),
        queryKey: ['opportunities', userSalesforceId],
        staleTime: 1000 * 60 * 10, 
        enabled: !!userSalesforceId
    });
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}


const INSIGHT_DASHBOARD_TAB = "insight_dashboard_tab";

export function getDashboardTabCookie(): 'clients' | 'opportunities' {
    const dashboardTab = window.localStorage.getItem(INSIGHT_DASHBOARD_TAB);
    if (!dashboardTab) return 'clients';
    return dashboardTab as 'clients' | 'opportunities';
}

export function setDashboardTabCookie(newTab: 'clients' | 'opportunities'): void {
    window.localStorage.setItem(INSIGHT_DASHBOARD_TAB, newTab);
}

export function useSnBases(): SnBasesIndeterminate {
    const { status, data, error } = useQuery<SnBases, Error>({
        queryFn: () => backend
            .get<SnBases>(`/snBaseUrls`)
            .then(res => res.data),
        queryKey: ['snBaseUrls'],
        staleTime: Infinity,
        enabled: true
    });
    
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}



export function useRegionalSnUrl() {
    const snUrlBases = useSnBases();
    const region = useRegion();

    if (region === null) return Error("Region not found");
    if (snUrlBases) {
        if (snUrlBases instanceof Error) return snUrlBases;
        else return snUrlBases[region];
    }
    
    return null;
}