import { Box, Typography } from "@mui/material";
import InheritableButtonBase from "src/components/InheritableButtonBase";
import { SalesforceOpportunityEdge } from "src/types";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const domParser = new DOMParser();

interface OpportunityCompanyDisplayProps {
    opportunity: SalesforceOpportunityEdge
}

export function OpportunityCompanyDisplay({opportunity}: OpportunityCompanyDisplayProps) {
    const parsedDoc = domParser.parseFromString(opportunity.node.Account.DisplayValue, 'text/html');
    const parsedStr = parsedDoc.documentElement.textContent; 
    return (
        <Box sx={{width: '100%'}}>
            {parsedStr}
        </Box>
    )
}




interface OpportunityNameDisplayProps {
    opportunity: SalesforceOpportunityEdge
}

export function OpportunityNameDisplay({opportunity}: OpportunityNameDisplayProps) {
    const isTouchScreen = window.matchMedia('(pointer: coarse)').matches;
    const additionalCss = isTouchScreen ? {} : {'&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' }}
    const parsedDoc = domParser.parseFromString(opportunity.node.Name.value, 'text/html');
    const parsedStr = parsedDoc.documentElement.textContent; 

  
    return (
        <InheritableButtonBase 
            sx={{borderRadius: '4px', paddingX: '4px', paddingY: '2px', m: '2px', ...additionalCss, cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'start'}}
            key={`opportunity-name-display-${opportunity.node.Id}`}   
            onClick={() => window.open(`https://thrivenetworks.lightning.force.com/lightning/r/Opportunity/${opportunity.node.Id}/view`, "_blank")}
        >
            <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <Typography variant='subtitle2'>{parsedStr}</Typography>
                <OpenInNewIcon sx={{fontSize: '16px', ml:1}} />
            </Box>
        </InheritableButtonBase>
    )
}

interface OpportunityNextStepDisplayProps {
    opportunity: SalesforceOpportunityEdge
}

export function OpportunityNextStepDisplay({opportunity}: OpportunityNextStepDisplayProps) {
    const parsedDoc = domParser.parseFromString(opportunity.node.NextStep.value, 'text/html');
    const parsedStr = parsedDoc.documentElement.textContent;  
    const parsedStrNoNull = parsedStr === 'null' ? '' : parsedStr;
    return (
        <Box sx={{width: '100%'}}>
            {parsedStrNoNull}
        </Box>
    )
}



interface OpportunityAmountDisplayProps {
    opportunity: SalesforceOpportunityEdge, 
    amountVar: 'R_MRR__c' | 'R_NRC__c'
}

export function OpportunityAmountDisplay({opportunity, amountVar}: OpportunityAmountDisplayProps) {
    const amount = opportunity.node[amountVar]?.value;
    return (
        <Box sx={{width: '100%'}}>
            <Box sx={{mr:2}} component='span'>
            ${Math.round(amount).toLocaleString()}
            </Box>
        </Box>
    )
}

