import { useQuery } from "@tanstack/react-query";
import { useBackend } from "./useRegion";
import { CasesAndQry } from "src/types";

export function useCompanyCases(accountSysIds:string, YYYYMMDD:string, YYYYMMDD7DaysAgo:String){
    accountSysIds = accountSysIds.split(",").sort().join(",")

    const backend = useBackend(); 
    const { status, data, error } = useQuery<CasesAndQry, Error>({
        queryFn: () => backend
            .get<{ result: CasesAndQry }>("/getCasesWithQry" + 
                `?accountSysIds=${accountSysIds}` + 
                `&startDateYYYY-MM-DD=${YYYYMMDD7DaysAgo}` + 
                `&endDateYYYY-MM-DD=${YYYYMMDD}` + 
                `&priorityIds=1`
            )
            .then(res => res.data.result),
        queryKey: ['companyCases', accountSysIds, YYYYMMDD, YYYYMMDD7DaysAgo],
        staleTime: 1000 * 60 * 10, 
        enabled: accountSysIds.length > 0
    });
    if (status === 'pending') return null;
    if (status === 'error') return error;
    else return data;
}
