import { Box, ButtonBase,  Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery, useTheme } from "@mui/material"

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { AsyncStateEventual, DashboardColumn } from "src/types";
import { grey } from "@mui/material/colors";
import { SortableColumnTypes } from "./ClientGridItems";

interface DashboardTableProps<colNames, sortableColNames, row> {
    rows: AsyncStateEventual<row[]>
    orderBy: sortableColNames | null
    setOrderBy: (sortBy: sortableColNames) => void
    orderByDesc: (-1 | 1)
    setOrderByDesc: (sortByDesc: (-1 | 1)) => void
    columns: DashboardColumn<colNames, row>[]
    columnsToShow: colNames[]
    sortableColumns: colNames[]
    maxHeightPx: number
    companiesLoaded: boolean

}

export function DashboardTable<colNames, sortableColNames, row>({rows, companiesLoaded, orderBy, setOrderBy, orderByDesc, setOrderByDesc, columns, columnsToShow, sortableColumns, maxHeightPx}:DashboardTableProps<colNames, sortableColNames, row>){
    const theme = useTheme();
    const headerClick = (colname: SortableColumnTypes) => {
        if(orderBy === colname) {
            setOrderByDesc((orderByDesc * -1) as (-1 | 1));
        } else {
            setOrderBy(colname as sortableColNames);
            setOrderByDesc(1);
        }
    }

    const vSmallScreen = useMediaQuery("(max-width: 600px)");
    const maxColWidth = vSmallScreen ? '100px' : '170px';

    return(
        <Box sx={{display: 'flex', flexDirection: 'column', backgroundColor: grey[100], borderRadius: '5px', maxHeight: maxHeightPx,}}>
            <TableContainer
                sx={{
                    width: '100%',
                    height: '100%',
                    overflowY: 'auto',
                    overflowX: 'auto',
                    '&::-webkit-scrollbar': {
                        width: '10px', 
                        height: '10px'
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: grey[300], 
                        borderRadius: '6px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.secondary.light,
                        borderRadius: '6px',
                        border: `2px solid ${theme.palette.secondary.main}`
                    }
                }}
            >
                <Table>
                    {/* top negative css to avoid scrolling cells from appearing on top of header  */}
                    <TableHead sx={{top: "-1px"}}> 
                        <TableRow >
                            {
                                columns
                                    .filter(col => columnsToShow.includes(col.name))                                            
                                    .map((col, ind) => {
                                        // zIndex to lay over scroll elements while sticky;  border radius to avoid coflict with wrapping box
                                        const addCss = ind === 0 ? { left: 0, zIndex: 3, borderRadius: "5px 0px 0px 0px" } : {};
                                        return (
                                            <TableCell sx={{px:'1px', py:0, 
                                                minWidth: col.colPx ? `${col.colPx}px` : vSmallScreen ? maxColWidth : col?.nameCol ? '300px' : maxColWidth,
                                                top: "-1px",  // to avoid scrolling cells from appearing on top of header
                                                position: 'sticky', zIndex: 2, backgroundColor: grey[100], ...addCss}} key={"header-cell" + col.name}>
                                                {
                                                    !sortableColumns.includes(col.name) ? 
                                                        // <Box sx={{py:3, px:.5, width: '100%', display: 'flex', alignItems: 'start', justifyContent: 'start', textAlign: 'left'}}>{col.name}</Box> :
                                                        <></> : 
                                                        <ButtonBase 
                                                            onClick={() => headerClick(col.name as SortableColumnTypes)} 
                                                            sx={{py:3, px:.5, width: '100%', height: '10px',  textAlign: 'start', display: 'flex', justifyContent: col?.rightAlign ? 'end' : 'start'}}>
                                                            {
                                                                col.label ? 
                                                                    <>
                                                                        <Box component="span">{col.label}</Box>
                                                                        {orderBy !== col.name ? <></> : orderByDesc < 1 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                                                    </> : 
                                                                    // Some table headers are empty (kaseya), so center the sort icon
                                                                    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
                                                                        {orderBy !== col.name ? <></> : orderByDesc < 1 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                                                                    </Box>
                                                            }
                                                        </ButtonBase>
                                                }
                                            </TableCell>
                                        )
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody >
                        {
                            !companiesLoaded || !rows ? 
                                [1,2,3,4,5,6,7,8,9,10].map((row, rowInd) => {
                                    const bgColor = rowInd % 2 === 0 ? grey[100] : grey[50];
                                    return (
                                        <TableRow key={"skeleton-row-" + row.toString()}>
                                            {
                                                columns
                                                    .filter(col => columnsToShow.includes(col.name))                                            
                                                    .map((col) => {
                                                    return (
                                                        <TableCell sx={{px:.5, py:1, backgroundColor: bgColor}} key={"skeleton-cel" + String(col.name) + row }><Skeleton variant="rounded" sx={{ fontSize: '1rem', px:.5 }} /></TableCell>
                                                    )
                                                })
                                            }
                                        </TableRow>
                                    )})
                            : 
                            rows.map((el, rowInd) => {
                                const bgColor = rowInd % 2 === 1 ? grey[100] : grey[50];
                                //@ts-ignore last part of the types I didn't work out.  If I only allowed one id field, and I mapped the hooks to use that id field, this would be necessary?  Just a thought - this works for now. 
                                const rowId = el?.node?.Id ? el.node.Id : el.sys_id;
                                return (
                                    <TableRow key={`${rowId}-dashboard-row`}>
                                        {
                                            columns
                                                .filter(col => columnsToShow.includes(col.name))                                            
                                                .map((col, ind) => {
                                                    const addCss = ind === 0 ? {position: 'sticky', left: 0, zIndex: 1} : {};
                                                    return (
                                                        <TableCell sx={{px:"1px", py:0, backgroundColor: bgColor, textAlign: col?.rightAlign ? 'right' : 'left',  ...addCss}} key={`${rowId}-${String(col.name)}`} >
                                                            {
                                                                col.displayFn(el) 
                                                            }
                                                        </TableCell>
                                                    )
                                                })
                                        }
                                    </TableRow>
                                )
                            })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    )
}