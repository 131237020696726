import { Box, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { CompanyPriorityCaseCt, CsatScore, ExpiringContractCt, Mrr, NameDisplay, Qip, SalesforceIcon, ProfitabilityDisplay } from './ClientTableCellDisplayFns';
import { DashboardTable } from './DashboardTable';

import CompanyBadges, { getBadgesForCompany } from '../Companies/About/CompanyBadges';
import type { CasesAndQryEventual, CompanyOption, CsatRespEventual, DashboardColumn, DashboardColumnTypes, ExpiringContractCtMapEventual, InvoiceTotalsEventual, ProfitabilityEventual, QipMapEventual, SalesforceIdMapEventual } from '../../types';
import { useMemo, useState } from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import assertively from "src/util/assertively";
import { useRegionalSnUrl } from "src/hooks/useCsatAndOtherDashboard";
import { TooltipOpenTabWrapper } from "src/components/TooltipOpenTabWrapper";


interface CompanyListProps {
    companies: CompanyOption[] | null,
    companiesLoaded: boolean,
    casesAndQry: CasesAndQryEventual, 
    setCompanyCaseFilterId: (id: string) => void, 
    csat: CsatRespEventual
    qipMap: QipMapEventual
    profitability: ProfitabilityEventual
    profitabilityDateStr: string
    salesforceIdMap: SalesforceIdMapEventual, 
    expiringContractCtMap: ExpiringContractCtMapEventual
    invoiceLast3Map: InvoiceTotalsEventual
    invoiceLast1Map: InvoiceTotalsEventual
}

type LimitedDashboardColumnTypes = {
    [key in DashboardColumnTypes]: key;
  };
// making a type thats a subset of DashboardColumnTypes - feels like this should have been simpler
export type SortableColumnTypes = keyof Pick<LimitedDashboardColumnTypes, "name" | "status" | "csat" | "qip" | "priority cases" | "profitability" | 'expiring contract count' | 'mrr3' | 'mrr1'>;



export function CompanyList({companies, companiesLoaded, casesAndQry, setCompanyCaseFilterId, csat, qipMap, 
        profitability, profitabilityDateStr, salesforceIdMap, expiringContractCtMap, invoiceLast3Map, invoiceLast1Map}: CompanyListProps) {
    const [sortBy, setSortBy] = useState<SortableColumnTypes | null>(null);
    const [sortByDesc, setSortByDesc] = useState<-1 | 1>(1);

    const csatAccum = useMemo(() => {
        return csat ? csat.reduce((acc: Record<string, Record<string, number>>, el) => {
            if(!acc[el.group['instance.task_id.company']]){
                acc[el.group['instance.task_id.company']] = {}; 
                acc[el.group['instance.task_id.company']][el.group.string_value] = el.count; 
            } else {
                if(acc[el.group['instance.task_id.company']][el.group.string_value]){
                    acc[el.group['instance.task_id.company']][el.group.string_value] += el.count; 
                } else {
                    acc[el.group['instance.task_id.company']][el.group.string_value] = el.count; 
                }
            }
            return acc; 
        }, {}) : null;
    }, [csat])  


    // move this to a cookie? 
    const columnsToShow: DashboardColumnTypes[] = ['name', 'csat', 'qip', 'priority cases', 'profitability', 'status', 'salesforce link', 'expiring contract count', 'mrr3', 'mrr1'];


    // allows the function to Programmatically assess which columns are sortable

    const sortFunctions: Record<SortableColumnTypes, (a: CompanyOption, b: CompanyOption) => number> = {
        name: (a: CompanyOption,b: CompanyOption) => a.name < b.name ? -1 * sortByDesc : 1 * sortByDesc, 
        status: (a: CompanyOption, b: CompanyOption) => statusSort(a,b, sortByDesc),
        csat: (a: CompanyOption, b: CompanyOption) => csatSort(a,b, csatAccum, sortByDesc),
        qip: (a: CompanyOption, b: CompanyOption) => qipSort(a,b, qipMap, sortByDesc),
        'priority cases': (a: CompanyOption, b: CompanyOption) => priorityCaseSort(a,b, casesAndQry, sortByDesc),
        profitability: (a: CompanyOption, b: CompanyOption) => {
            const pSort = profitabilitySort(a,b, profitability, sortByDesc); 
            return pSort;
        }, 
        'expiring contract count': (a: CompanyOption, b: CompanyOption) => expiringContractCtSort(a, b, expiringContractCtMap, sortByDesc), 
        'mrr1': (a: CompanyOption, b:CompanyOption) => mrrSort(a,b, invoiceLast1Map, sortByDesc),
        'mrr3': (a: CompanyOption, b:CompanyOption) => mrrSort(a,b, invoiceLast3Map, sortByDesc),
    }
    const sortableCols = Object.keys(sortFunctions) as DashboardColumnTypes[]; 

    const defaultSortFn = (a: CompanyOption, b: CompanyOption) => {
        return a.u_status === "At Risk" && b.u_status !== "At Risk" ? -1 : 1;
    };    

    const sortFunction = !sortBy ? defaultSortFn : sortFunctions[sortBy];
    companies?.sort(sortFunction);


    const columns: DashboardColumn<DashboardColumnTypes, CompanyOption>[] = [
        {name: 'name', label: 'Name', nameCol: true, displayFn: (row: CompanyOption) => <NameDisplay company={row} />},
        {name: 'salesforce link', label: "Salesforce", colPx: 50, displayFn: (row: CompanyOption) => <SalesforceIcon company={row} salesforceIdMap={salesforceIdMap}/>},   
        {name: 'status', label: 'Status', displayFn: (row: CompanyOption) => <CompanyBadges company={row} justifyContentSetting='flex-start' showTooltips={false} />},
        {name: 'expiring contract count', label: 'Expiring Contracts', colPx: 50, displayFn: (row: CompanyOption) => <ExpiringContractCt companySysId={row.sys_id} expiringContractCtMap={expiringContractCtMap} />},
        {name: 'profitability', label: 'Profitability', displayFn: (row: CompanyOption) => <ProfitabilityDisplay company={row} profitability={profitability} profitabilityDateStr={profitabilityDateStr}/>},
        {name: 'csat', label: 'CSAT', colPx: 100, displayFn: (row: CompanyOption) => <CsatScore companySysId={row.sys_id} csatAccum={csatAccum} />},
        {name: 'qip', label: 'QIP', colPx: 50, displayFn: (row: CompanyOption) => <Qip companySysId={row.sys_id} qipMap={qipMap} />},
        {name: 'priority cases', label: 'Priority Cases', colPx: 50, displayFn: (row: CompanyOption) => <CompanyPriorityCaseCt count={casesAndQry ? casesAndQry.cases.filter(el => el.accountSysId === row.sys_id).length : null} setCompanyCaseFilterId={setCompanyCaseFilterId} companySysId={row.sys_id}/>},
        {name: 'mrr1', label: 'MRR $ (Last Mth)', colPx: 100, rightAlign: true, displayFn: (row: CompanyOption) => <Mrr companySysId={row.sys_id} invoiceTotalsMap={invoiceLast1Map} divisor={1}  />},
        {name: 'mrr3', label: 'Avg MRR $ (Last 3 Mths)', colPx: 100, rightAlign: true, displayFn: (row: CompanyOption) => <Mrr companySysId={row.sys_id} invoiceTotalsMap={invoiceLast3Map} divisor={3} />}, 
    ]; 

    if(companiesLoaded && companies && !companies.length) return <Typography variant='caption' sx={{ml:"2px"}}>No results.</Typography>;
    return (
        <DashboardTable<DashboardColumnTypes, SortableColumnTypes, CompanyOption> companiesLoaded={companiesLoaded} columns={columns} orderBy={sortBy} sortableColumns={sortableCols} rows={companies} 
            setOrderBy={setSortBy} orderByDesc={sortByDesc} setOrderByDesc={setSortByDesc} columnsToShow={columnsToShow}
            maxHeightPx={500}/>
    );

}




function statusSort(a: CompanyOption, b: CompanyOption, modifier: -1 | 1 = 1) {

    const aBadges = getBadgesForCompany(a); 
    const bBadges = getBadgesForCompany(b);

    const aText = aBadges.map(el => el.data).join(' ');
    const bText = bBadges.map(el => el.data).join(' ');

    return aText < bText ? -1 * modifier : 1 * modifier; 
}

function csatSort(a: CompanyOption, b: CompanyOption, csatAccum: Record<string, Record<string, number>> | null, modifier: -1 | 1 = 1) {
    if(!csatAccum) return 0;
    const aCsat = csatAccum[a.sys_id] || {}; 
    const bCsat = csatAccum[b.sys_id] || {}; 

    const aSatisfied = aCsat['Satisfied'] || 0; 
    const aNeutral = aCsat['Neutral'] || 0;
    const aDissatisfied = aCsat['Dissatisfied'] || 0;

    const bSatisfied = bCsat['Satisfied'] || 0;
    const bNeutral = bCsat['Neutral'] || 0;
    const bDissatisfied = bCsat['Dissatisfied'] || 0;

    return aSatisfied === bSatisfied ? 
        (aNeutral === bNeutral ? 
            (aDissatisfied === bDissatisfied ? 0 : aDissatisfied < bDissatisfied ? -1 * modifier : 1 * modifier) : 
            aNeutral < bNeutral ? -1 * modifier : 1 * modifier) : 
        aSatisfied < bSatisfied ? -1 * modifier : 1 * modifier; 
}


function qipSort(a: CompanyOption, b: CompanyOption, qipMap: QipMapEventual, modifier: -1 | 1 = 1) {
    if(!qipMap) return 0;
    let aQip = qipMap[a.sys_id] || 0; 
    aQip = aQip.toString().toLowerCase() === 'yes' ? 1 : 0;
    let bQip = qipMap[b.sys_id] || 0; 
    bQip = bQip.toString().toLowerCase() === 'yes' ? 1 : 0;
    return aQip === bQip ? 0 : aQip < bQip ? -1 * modifier : 1 * modifier; 
}


function priorityCaseSort(a: CompanyOption, b: CompanyOption, casesAndQry: CasesAndQryEventual, modifier: -1 | 1 = 1) {
    if(!casesAndQry) return 0;
    const aCt = casesAndQry.cases.filter(el => el.accountSysId === a.sys_id).length; 
    const bCt = casesAndQry.cases.filter(el => el.accountSysId === b.sys_id).length; 
    return aCt === bCt ? 0 : aCt < bCt ? -1 * modifier : 1 * modifier; 
}

function profitabilitySort(a: CompanyOption, b: CompanyOption, profitability: ProfitabilityEventual, modifier: -1 | 1 = 1) {
    if(!profitability) return 0;

    const compAProfitability = profitability[a.sys_id];
    const compBProfitability = profitability[b.sys_id];

    const revA = parseFloat(compAProfitability["GM Invoice Revenue"]) || 0; 
    if(revA === 0) return -1 * modifier;
    const amTimeA = parseFloat(compAProfitability["GM Account Management Time"]) || 0; ; 
    const prodCostA = parseFloat(compAProfitability["GM Product Cost"]) || 0; 
    const resCostMonthlyA = parseFloat(compAProfitability["GM Resource Cost Monthly"]) || 0; 
    const stratLeadCostA = parseFloat(compAProfitability['GM Strategic Lead Cost']) || 0;     

    const acMgmtCostA = stratLeadCostA && amTimeA ? stratLeadCostA * (amTimeA / 3600) : 0;
    const profitA = revA - prodCostA - acMgmtCostA - resCostMonthlyA;
    const profitPercentA = (profitA / revA) * 100;

    const revB = parseFloat(compBProfitability["GM Invoice Revenue"]) || 0;
    if(revB === 0) return 1 * modifier
    const amTimeB = parseFloat(compBProfitability["GM Account Management Time"]) || 0; ;
    const prodCostB = parseFloat(compBProfitability["GM Product Cost"]) || 0;
    const resCostMonthlyB = parseFloat(compBProfitability["GM Resource Cost Monthly"]) || 0;
    const stratLeadCostB = parseFloat(compBProfitability['GM Strategic Lead Cost']) || 0;

    const acMgmtCostB = stratLeadCostB && amTimeB ? stratLeadCostB * (amTimeB / 3600) : 0;
    const profitB = revB - prodCostB - acMgmtCostB - resCostMonthlyB;
    const profitPercentB = (profitB / revB) * 100;

    return profitPercentA === profitPercentB ? 0 : profitPercentA < profitPercentB ? -1 * modifier : 1 * modifier;

}

function expiringContractCtSort(a: CompanyOption, b: CompanyOption, expiringContractCtMap: ExpiringContractCtMapEventual, modifier: -1 | 1 = 1) {
    if(!expiringContractCtMap) return 0;
    const aCt = expiringContractCtMap[a.sys_id] || 0; 
    const bCt = expiringContractCtMap[b.sys_id] || 0; 
    return aCt === bCt ? 0 : aCt < bCt ? -1 * modifier : 1 * modifier; 
}

function mrrSort(a: CompanyOption, b: CompanyOption, invoiceMap: InvoiceTotalsEventual, modifier: -1 | 1 = 1){
    if(!invoiceMap) return 0;
    const aMrr = invoiceMap[a.sys_id]?.mrrTotal || 0;
    const bMrr = invoiceMap[b.sys_id]?.mrrTotal || 0;
    return aMrr === bMrr ? 0 : aMrr < bMrr ? -1 * modifier : 1 * modifier;
}


interface AtRiskClientCtProps {
    userAccounts: CompanyOption[], 
    companiesLoaded: boolean
}

export function AtRiskClientCt({userAccounts, companiesLoaded}: AtRiskClientCtProps) {
    const theme = useTheme();

    const atRiskCt = !userAccounts ? "" : userAccounts.filter(el => el.u_status === "At Risk").length;
    
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py:1}}>
            <Typography variant='subtitle1' sx={{textAlign: 'center'}}>At Risk Clients</Typography>
            <Box>
                {
                    companiesLoaded ? 
                        <Typography variant="h5" sx={{textAlign: 'center', color: atRiskCt && atRiskCt > 0 ? theme.palette.warning.dark : theme.palette.text.primary}}>{atRiskCt}</Typography> : 
                        <Skeleton variant="rounded" width={30} height={30} />
                }
            </Box>
        </Box>
    )
}

interface ClientCtSquareProps {
    userAccounts: CompanyOption[],
    companiesLoaded: boolean
}

export function ClientCtSquare({userAccounts, companiesLoaded}: ClientCtSquareProps) {

    const ct = !userAccounts ? "" : userAccounts.length;
    
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py:1}}>
            <Typography variant='subtitle1' sx={{textAlign: 'center'}}>Clients</Typography>
            <Box>
                {
                    companiesLoaded ? 
                        <Typography variant="h5" sx={{textAlign: 'center'}}>{ct}</Typography> : 
                        <Skeleton variant="rounded" width={30} height={30} />
                }
            </Box>
        </Box>
    )
}


interface ExpiringContractCtSquareProps {
    expiringContractCtMap: ExpiringContractCtMapEventual,   
}

export function ExpiringContractCtSquare({expiringContractCtMap}: ExpiringContractCtSquareProps){
    const theme = useTheme(); 
    let ct = 0; 
    if(expiringContractCtMap){
        Object.keys(expiringContractCtMap).forEach(key => ct += expiringContractCtMap[key]);
    }

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py:1}}>
            <Typography variant='subtitle1' sx={{textAlign: 'center'}}>Expiring Contracts</Typography>
            <Box>
                {
                    expiringContractCtMap ? 
                        <Typography variant="h5" sx={{textAlign: 'center', color: ct > 0 ? theme.palette.warning.dark : theme.palette.text.primary}}>{ct}</Typography> : 
                        <Skeleton variant="rounded" width={30} height={30} />
                }
            </Box>
        </Box>
    )

}



interface BookOfBusinessProps {
    invoiceLast1Map: InvoiceTotalsEventual,
    invoiceLast3Map: InvoiceTotalsEventual,
}

export function BookOfBusiness({invoiceLast1Map, invoiceLast3Map}: BookOfBusinessProps) {
    let last1 = 0;
    let last3 = 0;
    if(invoiceLast1Map) Object.keys(invoiceLast1Map).forEach((key) => {
        last1 += parseFloat(invoiceLast1Map[key].mrrTotal);
    })
    if(invoiceLast3Map) Object.keys(invoiceLast3Map).forEach((key) => {
        last3 += parseFloat(invoiceLast3Map[key].mrrTotal);
    })
    last3 = last3 / 3;

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py:1}}>
            <Typography variant='subtitle1' sx={{textAlign: 'center'}}>Recurring Invoicing</Typography>
            <Box>
                { 
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column',  justifyContent: 'center', alignItems: 'center', mr:.5}}>
                            {
                                !invoiceLast1Map || !invoiceLast3Map ? 
                                    <Skeleton variant="rounded" width={60} height={30} /> : 
                                    <Typography variant="h6" sx={{textAlign: 'center'}}>${Math.round(last1).toLocaleString()}</Typography>
                            }
                            <Typography variant="caption" sx={{textAlign: 'center', ml:.5, color: 'grey'}}>Last Mth</Typography>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column',  justifyContent: 'center', alignItems: 'center', ml:.5}}>
                            {
                                !invoiceLast1Map || !invoiceLast3Map ? 
                                    <Skeleton variant="rounded" width={60} height={30} /> : 
                                    <Typography variant="h6" sx={{textAlign: 'center', ml:1}}>${Math.round(last3).toLocaleString()}</Typography>
                            }
                            <Typography variant="caption" sx={{textAlign: 'center', ml:.5, color: 'grey'}}>Last 3 Mths Avg</Typography>
                        </Box>
                    </Box>
                }
            </Box>
        </Box>
    )
}




interface PriorityCaseListProps {
    casesAndQry: CasesAndQryEventual
    userAccounts: CompanyOption[],
    companyCaseFilterId: string | null, 
    setCompanyCaseFilterId: (id: string) => void
}

export function PriorityCaseList({casesAndQry, userAccounts, companyCaseFilterId, setCompanyCaseFilterId}: PriorityCaseListProps) {

    const isLoading = !casesAndQry;
    const snUrlBase = assertively(useRegionalSnUrl());

    const cases = casesAndQry ? 
        companyCaseFilterId ? 
            casesAndQry.cases.filter(el => el.accountSysId === companyCaseFilterId) : 
            casesAndQry.cases : 
        [];

    return (
        <>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <Typography variant='subtitle1'>Priority 1 Cases</Typography>
                {
                    companyCaseFilterId && casesAndQry && casesAndQry?.cases.length  && <IconButton sx={{ml:.5, p:0}} onClick={() => setCompanyCaseFilterId("")}><FilterAltOffIcon sx={{fontSize: '16px', p:.5}} /></IconButton>
                }   
            </Box>
            <Box sx={{pb:.5}}><Typography variant='caption' sx={{color: 'gray'}}>From your accounts in the last 7 days.</Typography></Box>
            {isLoading ?
                [1,2].map((el, ind) => {
                    return (
                        <Box key={`priority-case-${ind}`} sx={{mb:.5}}>
                            <Skeleton variant="text" width={150} height={20} />
                            <Skeleton variant="text" width={225} height={16} />
                            <Skeleton variant="text" width={225} height={16} />
                        </Box>
                    )
                })
                : 
                <>
                    <Box>
                        {
                            !cases.length ? 
                                <Typography variant='caption'>No results.</Typography> :
                                cases
                                .sort((a,b) => new Date(a.opened_at) > new Date(b.opened_at) ? -1 : 1)
                                .map((el, ind) => {
                                const companyName = userAccounts?.find(c => c.sys_id === el.accountSysId)?.name || "Unknown Company";
                                return (
                                    <Box key={`priority-case-${ind}`} sx={{mb:.5}}>
                                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                                        <b>{companyName}</b> 
                                        {
                                            snUrlBase &&
                                            <Box>
                                                <TooltipOpenTabWrapper tooltipText="Open record in ServiceNow" openNewTabUrl={`${snUrlBase}/now/sow/record/sn_customerservice_case/${el.sys_id}`}>
                                                    <OpenInNewIcon sx={{fontSize: '12px'}}/>
                                                </TooltipOpenTabWrapper>
                                            </Box>
                                        }
                                        </Box>
                                        <Box>P{el.priority.id} | {el.number} | {el.short_description}</Box>
                                        <Box>Assigned to: {el.assigned_to.display} ({el.assignment_group.display}) | Opened: {new Date(el.opened_at).toLocaleDateString()}</Box>
                                    </Box>
                                )
                            })
                        }
                    </Box>
                </>
            }
        </>
    )
}
