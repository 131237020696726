import { Box, IconButton, Tooltip } from "@mui/material";
import { useState, PointerEvent } from "react";

interface TooltipOpenTabWrapperProps {
    tooltipText:string; 
    openNewTabUrl: string;
    children: React.ReactNode; 
    disabled?: boolean;
}



export function TooltipOpenTabWrapper({tooltipText, openNewTabUrl, children, disabled}: TooltipOpenTabWrapperProps){
    const [showTooltip, setShowTooltip] = useState(false);

    const onIconBtnClick = (e: PointerEvent<Element>) => {
        const pointerType = e.nativeEvent.pointerType;
        if (pointerType === "mouse") {
            window.open(`${openNewTabUrl}`,'_blank')
        } else {
            if (!showTooltip) {
                setShowTooltip(true);
            } else {
                if(!disabled) window.open(`${openNewTabUrl}`,'_blank')
                setShowTooltip(false);
            }
        }
    }


    return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center', p:0}}>
            <Tooltip title={
                <Box style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
                    <Box sx={{my:.5}}>{tooltipText}</Box>
                </Box>
            } open={showTooltip} onClose={() => setShowTooltip(false)} onOpen={() => setShowTooltip(true)} placement="top" leaveTouchDelay={3000}>
                <span onClick={(e) => onIconBtnClick(e as unknown as PointerEvent)}>
                    <IconButton disabled={disabled} sx={{  cursor: 'pointer', m:'1px', p:.5, display:'flex', alignItems: 'center' }} >
                        {children}
                    </IconButton>
                </span>
            </Tooltip>

        </Box>
    )
}