import Typography from '@mui/material/Typography';
import Box from "@mui/material/Box"; 

import type {  CompanyOption } from '../../types';

import { useUser, useUserWSysId } from "src/hooks/useUser";
import { useCompanyList } from "src/hooks/useCompanyList";
import assertively from "src/util/assertively";
import { Button,  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputLabel, LinearProgress, MenuItem,  Popover,  Select, Switch, useMediaQuery, useTheme } from '@mui/material';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';

import { useMemo, useState } from 'react';
import { useCompanyCases } from 'src/hooks/useCases';
import { grey } from '@mui/material/colors';
import { useCsat, useContractsExpiringCtMap, useProfitability, useQipMap, useSalesforceIdMap, useInvoiceTotals, useOpportunities } from 'src/hooks/useCsatAndOtherDashboard';
import { AtRiskClientCt, ClientCtSquare, CompanyList, ExpiringContractCtSquare, PriorityCaseList, BookOfBusiness } from './ClientGridItems';

import HandshakeIcon from '@mui/icons-material/Handshake';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { OpportunityCount, OpportunityList, OpportunityAmounts, Salesfunnel, OpportunityClosingCt, OpportunityClosingDateChart, SalesFunnelMrr } from './OppsGridItems';
import { getDashboardTabCookie, setDashboardTabCookie } from 'src/hooks/useCsatAndOtherDashboard';
import { getBadgesForCompany } from '../Companies/About/CompanyBadges';

interface UserDashboardProps {}

export default function UserDashboard(props: UserDashboardProps) {

    const [dashboardUser, setDashboardUser] = useState<{id: string, display: string} | null>(null);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const isScreenSmall = useMediaQuery('(max-width:1050px)');

    const initialClientsOrOpps = getDashboardTabCookie();
    const [clientsOrOpps, setClientsOrOppsState] = useState<'clients' | 'opportunities'>(initialClientsOrOpps);
    const setClientsOrOpps = (val: 'clients' | 'opportunities') => {
        setClientsOrOppsState(val);
        setDashboardTabCookie(val);
    }

    const [anchorElForFilters, setAnchorElForFilters] = useState<HTMLButtonElement | null>(null);

    const snUser = assertively(useUser());
    const companyList = assertively(useCompanyList());
    
    if(!dashboardUser && snUser) setDashboardUser({id: snUser.sys_id, display: ''});
    const dashboardUserId = dashboardUser?.id;
    const showingSelectedUser = dashboardUserId && dashboardUserId !== snUser?.sys_id;
    const isManager = snUser?.showManagerFunctions || false; 
    const [hideNonMrr, setHideNonMrr] = useState<boolean>(true);


    return (
        <>
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Box sx={{display: 'flex', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant='h6' sx={{}}>
                                {
                                    clientsOrOpps === 'clients' ? "My Clients" : "My Opportunities"
                                }
                                {
                                    showingSelectedUser &&
                                        <Box component="span" sx={{color: 'gray', ml:1}}>
                                            {`(for ${dashboardUser.display})`}
                                        </Box>
                                }
                            </Typography>
                        </Box>
                        <Box>
                            {
                                clientsOrOpps === 'clients' ?
                                    <Typography variant='caption' sx={{color: 'gray'}}>Accounts where you are the Strategic Lead, Security Lead, Account Owner, Client Success Manager or Enterprise Lead.</Typography>: 
                                    <Typography variant='caption' sx={{color: 'gray'}}>Salesforce opportunities where you are listed as the Owner.</Typography>
                            }
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}></Box>
                    <Box sx={{mr:.5}}>
                        {
                            isScreenSmall ?
                            <IconButton onClick={() => setClientsOrOpps(clientsOrOpps === 'clients' ? 'opportunities' : 'clients')}>
                                {clientsOrOpps === 'clients' ? <TravelExploreIcon /> : <HandshakeIcon />}
                            </IconButton> 
                            :
                            <Button 
                            startIcon={clientsOrOpps === 'clients' ? <TravelExploreIcon /> : <HandshakeIcon />}
                            onClick={() => setClientsOrOpps(clientsOrOpps === 'clients' ? 'opportunities' : 'clients')} sx={{ml:1}}>{clientsOrOpps === 'clients' ? "Show Opportunities" : "Show Clients"}</Button>
                        }
                    </Box>
                    {
                        clientsOrOpps === 'clients' &&
                        <Box>
                            <IconButton aria-describedby='popover-button-filter'  onClick={(e) => setAnchorElForFilters(e.currentTarget)}>
                                <FilterAltIcon />
                            </IconButton>
                            <Popover
                                id={'popover-button-filter'}
                                open={Boolean(anchorElForFilters)}
                                anchorEl={anchorElForFilters}
                                onClose={() => setAnchorElForFilters(null)}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                            >
                                <Box sx={{p:2, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'start'}}>
                                    <Typography variant='h6'>Filters</Typography>
                                    <FormControlLabel control={<Switch checked={hideNonMrr} onChange={() => setHideNonMrr(!hideNonMrr)} />} label="Hide Non-MRR" />
                                </Box>
                            </Popover>
                        </Box>
                    }
                    {
                        isManager &&
                        <Box>
                            <IconButton onClick={() => setModalOpen(true)}>
                                <PersonSearchIcon />
                            </IconButton>
                        </Box>
                    }
                </Box>
            </Grid>
            {
                clientsOrOpps === 'clients' ? 
                    <MyClientsGridItems companyList={companyList} dashboardUserId={dashboardUserId} hideNonMrr={hideNonMrr} /> : 
                    <MyOpporunitiesGridItems dashboardUserId={dashboardUserId} />
            }



        </Grid>

        <AccountManagerSelect modalOpen={modalOpen} setModalOpen={setModalOpen} setDashboardUser={setDashboardUser} companyList={companyList} />
        </>
    )
}


interface MyOpporunitiesGridItemsProps {
    dashboardUserId: string | undefined,
}

function MyOpporunitiesGridItems({dashboardUserId}: MyOpporunitiesGridItemsProps) {
    const dashboardUserFullInfo = assertively(useUserWSysId(dashboardUserId));
    const dashboardUserSfId = dashboardUserFullInfo?.u_salesforce_id || null;
    const opportunitiesResp = assertively(useOpportunities(dashboardUserSfId));
    const opportunities = opportunitiesResp?.data?.uiapi?.query?.Opportunity?.edges || null;
    const hideSquares = Boolean(dashboardUserFullInfo && !dashboardUserFullInfo.u_salesforce_id) || 
        Boolean(opportunitiesResp && opportunities?.length === 0); 


    return (
        <>
        {
            !hideSquares &&
            <>
            <Grid item xs={12} >
                <Grid container spacing={1}>
                    <Grid item xs={6} sm={3} >
                        <DashboardBg>
                            <OpportunityCount opportunities={opportunities} />
                        </DashboardBg>
                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <DashboardBg>
                            <OpportunityAmounts opportunities={opportunities} mrrOrNrc={'mrr'} />
                        </DashboardBg>
                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <DashboardBg>
                            <OpportunityAmounts opportunities={opportunities} mrrOrNrc={'nrc'} />
                        </DashboardBg>
                    </Grid>
                    <Grid item xs={6} sm={3} >
                        <DashboardBg>
                            <OpportunityClosingCt opportunities={opportunities} days={30} />
                        </DashboardBg>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={4} >
                <DashboardBg>
                    <OpportunityClosingDateChart opportunities={opportunities} />
                </DashboardBg>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
                <DashboardBg>
                    <Salesfunnel opportunities={opportunities} />
                </DashboardBg>
            </Grid>
            <Grid item xs={12} sm={6} md={4} >
                <DashboardBg>
                    <SalesFunnelMrr opportunities={opportunities} />
                </DashboardBg>
            </Grid>
            </>
        }
        <Grid item xs={12} >
            <OpportunityList opportunities={opportunities} hideSquares={hideSquares} />
        </Grid>
        </>
    )
}



interface MyClientsGridItemsProps {
    companyList: CompanyOption[] | null,
    dashboardUserId: string | undefined,
    hideNonMrr: boolean
}

function MyClientsGridItems({companyList, dashboardUserId, hideNonMrr}: MyClientsGridItemsProps) {
    const [companyCaseFilterId, setCompanyCaseFilterId] = useState<string | null>(null);
    const companiesLoaded = Boolean(companyList) && Boolean(dashboardUserId); 


    const userAccounts = useMemo(() => {
        return (!dashboardUserId || !companyList) ? [] : companyList.filter(el => {
            if(!dashboardUserId) return false;
            const hasRole = el.u_spc.id === dashboardUserId || 
                el.u_ae.id === dashboardUserId || el.u_cem.id === dashboardUserId || 
                el.u_security_lead.id === dashboardUserId || el.u_enterprise_lead.id === dashboardUserId; 
            if(hideNonMrr) {
                return hasRole && !getBadgesForCompany(el).some(el => el.data === 'NonMRR');
            }
            return hasRole;
        }) 
    }, [dashboardUserId, companyList, hideNonMrr]);
    const userAccountSysIds = userAccounts.map(el => el.sys_id);
    const hideNonAccountSquares = dashboardUserId && companyList && !userAccounts.length; 

    const _today = new Date();
    const _FourteenDaysAgo = new Date(_today.getTime() - (13 * 24 * 60 * 60 * 1000));
    const _OneEightyDaysAgo = new Date(_today.getTime() - (180 * 24 * 60 * 60 * 1000));
    // const _ThirtyDaysAgo = new Date(_today.getTime() - (600 * 24 * 60 * 60 * 1000));
    const profitablityMonthLookback = _today.getDate() < 15 ? 2 : 1;
    const _FirstDay = new Date(_today.getFullYear(), _today.getMonth()- profitablityMonthLookback, 1);
    // const _FirstDay = new Date(_today.getFullYear(), _today.getMonth()-3, 1);
    const YYYYMMDD = `${_today.getFullYear()}-${_today.getMonth()+1}-${_today.getDate()}`
    const YYYYMMDD14DaysAgo = `${_FourteenDaysAgo.getFullYear()}-${_FourteenDaysAgo.getMonth() + 1}-${_FourteenDaysAgo.getDate()}`;
    // request needed 2 digit month and day, so we add a 0 if needed
    const YYYYMMDDFirstDay = `${_FirstDay.getFullYear()}-${("0" +(_FirstDay.getMonth()+1)).slice(-2)}-${("0" + _FirstDay.getDate()).slice(-2)}`;
    const threeMonthsAgo = new Date(_today.getFullYear(), _today.getMonth()-3, 1);
    const startOfLastMonth = new Date(_today.getFullYear(), _today.getMonth()-1, 1);
    const endOfLastMonth = new Date(_today.getFullYear(), _today.getMonth(), 0);
    const YYYYMMDD3MonthsAgo = `${threeMonthsAgo.getFullYear()}-${threeMonthsAgo.getMonth()+1}-${threeMonthsAgo.getDate()}`; 
    const YYYYMMDDStartOfLastMonth = `${startOfLastMonth.getFullYear()}-${startOfLastMonth.getMonth()+1}-${startOfLastMonth.getDate()}`;
    const YYYYMMDDEndOfLastMonth = `${endOfLastMonth.getFullYear()}-${endOfLastMonth.getMonth()+1}-${endOfLastMonth.getDate()}`;


    const profitabilityDateStr = `${_FirstDay.getMonth() + 1}/${_FirstDay.getFullYear()}`;
    const casesAndQry = assertively(useCompanyCases(userAccountSysIds.join(","), YYYYMMDD, YYYYMMDD14DaysAgo,)); 
    const profitability = assertively(useProfitability(userAccountSysIds.join(","), YYYYMMDDFirstDay));
    const salesforceIdMap = assertively(useSalesforceIdMap(userAccountSysIds.join(",")));
    const invoiceLast3Map = assertively(useInvoiceTotals(userAccountSysIds.join(","), YYYYMMDD3MonthsAgo, YYYYMMDDEndOfLastMonth));
    const invoiceLast1Map = assertively(useInvoiceTotals(userAccountSysIds.join(","), YYYYMMDDStartOfLastMonth, YYYYMMDDEndOfLastMonth));

    const expiringContractDayThreshold = 90; 
    const expiringContractCtMap = assertively(useContractsExpiringCtMap(userAccountSysIds.join(","), expiringContractDayThreshold));

    const YYYYMMDD180DaysAgo = `${_OneEightyDaysAgo.getFullYear()}-${_OneEightyDaysAgo.getMonth() + 1}-${_OneEightyDaysAgo.getDate()}`;
    const metricSysIds = "9f7079ae1b287f00346da7d4bd4bcb0b"; 
    if(metricSysIds.split(",").length > 1) throw new Error("Only one metricSysId is allowed for this component.");
    const csat = assertively(useCsat(userAccountSysIds.join(","), YYYYMMDD180DaysAgo, metricSysIds));
    const qipMap = assertively(useQipMap(userAccountSysIds.join(",")));


    return (
        <>
        {
            !hideNonAccountSquares && 
                <Grid item xs={12} md={8}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <DashboardBg>
                                <ClientCtSquare userAccounts={userAccounts} companiesLoaded={companiesLoaded} />
                            </DashboardBg>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <DashboardBg>
                                <BookOfBusiness invoiceLast3Map={invoiceLast3Map} invoiceLast1Map={invoiceLast1Map} />
                            </DashboardBg>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <DashboardBg>
                                <ExpiringContractCtSquare expiringContractCtMap={expiringContractCtMap}/>
                            </DashboardBg>
                        </Grid>
                        <Grid item xs={12} sm={6} >
                            <DashboardBg>
                                <AtRiskClientCt userAccounts={userAccounts} companiesLoaded={companiesLoaded} />
                            </DashboardBg>
                        </Grid>
                    </Grid>
                </Grid>
        }
        {
            !hideNonAccountSquares && 
                <Grid item xs={12} md={4}>
                    <DashboardBg maxHeightPx={212}>
                        <PriorityCaseList casesAndQry={casesAndQry} userAccounts={userAccounts} companyCaseFilterId={companyCaseFilterId} setCompanyCaseFilterId={setCompanyCaseFilterId}/>
                    </DashboardBg>
                </Grid>
        }
        <Grid item xs={12} >
            {/* Bg applied in element */}
            <CompanyList companiesLoaded={companiesLoaded} companies={userAccounts} casesAndQry={casesAndQry} 
                setCompanyCaseFilterId={setCompanyCaseFilterId} csat={csat} qipMap={qipMap} profitability={profitability}
                profitabilityDateStr={profitabilityDateStr}
                salesforceIdMap={salesforceIdMap}
                expiringContractCtMap={expiringContractCtMap}
                invoiceLast3Map={invoiceLast3Map}
                invoiceLast1Map={invoiceLast1Map}
            />
        </Grid>        
        </>
    )
}





interface AccountManagerSelectProps {
    modalOpen: boolean,
    setModalOpen: (val: boolean) => void,
    setDashboardUser: (val: {id: string, display: string} | null) => void,
    companyList: CompanyOption[] | null,
}

function AccountManagerSelect({modalOpen, setModalOpen, companyList, setDashboardUser}: AccountManagerSelectProps) {

    const [dropdownUser, setDropdownUser] = useState<{id: string, display: string} | null>(null);

    const accountManagers: {id: string, display: string}[] | null = useMemo(() => {
        if(!companyList) return null;
        const amMap: Record<string, {id: string, display: string}> = {}; 
        companyList.forEach(el => {
            if(!amMap[el.u_spc.id]) amMap[el.u_spc.id] = el.u_spc;
            if(!amMap[el.u_ae.id]) amMap[el.u_ae.id] = el.u_ae;
            if(!amMap[el.u_cem.id]) amMap[el.u_cem.id] = el.u_cem;
            if(!amMap[el.u_security_lead.id]) amMap[el.u_security_lead.id] = el.u_security_lead;
            if(!amMap[el.u_enterprise_lead.id]) amMap[el.u_enterprise_lead.id] = el.u_enterprise_lead;
        });
        return Object.values(amMap);
    }, [companyList]);
    
    return (

        <Dialog
            open={modalOpen}
            onClose={() => setModalOpen(false)}
        >
            <DialogTitle>Change Dashboard User?</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Update the dashboard to display values for another user.
                </DialogContentText>
                <Box sx={{my:2}}>
                    {
                        !accountManagers ? 
                            <LinearProgress sx={{my:4}} /> : 
                            <FormControl fullWidth >
                                <InputLabel id="account-manager-dropdown-label">Users</InputLabel>
                                <Select
                                    labelId="account-manager-dropdown-label"
                                    id="account-manager-dropdown"
                                    value={dropdownUser ? dropdownUser.id : ''}
                                    label="Users"
                                    onChange={(e) => setDropdownUser(accountManagers.find(el => el.id === e.target.value as string) || null )}
                                >
                                    {
                                        accountManagers.sort((a,b) => a.display < b.display ? -1 : 1).map((el) => {
                                            return <MenuItem key={`account-manager-menu-item-${el.id}`} value={el.id}>{el.display}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                    }
                </Box>
            </DialogContent>
            <DialogActions>
                <Button color="error" variant="contained"  onClick={() => {setDashboardUser(null); setModalOpen(false); setDropdownUser(null)}}>Reset</Button>
                <Button color="success" variant="contained" onClick={() => {setDashboardUser(dropdownUser); setModalOpen(false)}}>Update Dashboard</Button>
            </DialogActions>
        </Dialog>


    )


}




interface DashboardBgProps {
    children: React.ReactNode, 
    maxHeightPx?: number,
}

function DashboardBg({children, maxHeightPx}: DashboardBgProps) {
    const maxHeightSx = maxHeightPx ? { maxHeight: `${maxHeightPx}px` } : {};
    const theme = useTheme(); 
    return (
        <Box sx={{
            backgroundColor: grey[100], borderRadius: "5px", overflowY: 'auto', ...maxHeightSx, height: '100%', overflowX: 'hidden',
            '&::-webkit-scrollbar': {
                width: '10px'
            },
            '&::-webkit-scrollbar-track': {
                backgroundColor: grey[300], 
                borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: theme.palette.secondary.light,
                borderRadius: '6px',
                border: `2px solid ${theme.palette.secondary.main}`
            }
        }}>
            <Box sx={{p:1}}>
                {children}
            </Box>
        </Box>
    )
}