import { Badge, Box, ButtonBase, Chip, Skeleton, Tooltip, Typography, useTheme } from "@mui/material";

import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';

import { grey, orange } from "@mui/material/colors";

import { CompanyOption, ExpiringContractCtMapEventual, InvoiceTotalsEventual, SalesforceIdMapEventual, ProfitabilityEventual } from "src/types";
import InheritableButtonBase from "src/components/InheritableButtonBase";
import useRegionPath from "src/hooks/useRegionPath";

import salesforceImg from "src/assets/salesforce.svg"; 
import { TooltipOpenTabWrapper } from "src/components/TooltipOpenTabWrapper";


interface SalesforceIconProps {
    company: CompanyOption
    salesforceIdMap: SalesforceIdMapEventual
}

export function SalesforceIcon({company, salesforceIdMap}: SalesforceIconProps) {
    if(!company || !salesforceIdMap) return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <Skeleton variant="circular" width={26} height={26} />
        </Box>
    )
    const salesforceId = salesforceIdMap[company.sys_id];

    const hoverText = salesforceId ? 
     "Salesforce ID: " + salesforceId + "\n\nClick to open Salesforce record\nin a new tab": 
     "No Salesforce ID."

    return (
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <TooltipOpenTabWrapper tooltipText={hoverText} openNewTabUrl={`https://thrivenetworks.lightning.force.com/lightning/r/Account/${salesforceId}/view`} disabled={!salesforceId}>
                <Box sx={{width: '25px', height: '25px', pt: '1px'}}>
                    <img src={salesforceImg} alt="Salesforce Link" style={{width: '25px', height: '25px', opacity: salesforceId ? 1 : .1}}/>
                </Box>
            </TooltipOpenTabWrapper>
        </Box>
    )
}

interface NameDisplayProps {
    company: CompanyOption
}

export function NameDisplay({company}: NameDisplayProps) {
    const isTouchScreen = window.matchMedia('(pointer: coarse)').matches;
    const additionalCss = isTouchScreen ? {} : {'&:hover': { backgroundColor: '#EAEAEA', borderRadius: '3px' }}
    const region  = useRegionPath(); 
  
    return (
        <InheritableButtonBase 
            sx={{borderRadius: '4px', paddingX: '4px', paddingY: '2px', m: '2px', ...additionalCss, cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'start'}}
            key={`company-name-display-${company.sys_id}`}
            onClick={() => window.open(`/${region}/companies/${company.u_company_id}/${company.sys_id}`, "_blank")}
        >
            <Typography variant='subtitle1'>{company.name}</Typography>
        </InheritableButtonBase>
    )
}

interface QipProps {
    companySysId: string,
    qipMap: Record<string, string> | null
}

export function Qip({companySysId, qipMap}: QipProps) {
    if(!qipMap) return <Skeleton variant="rounded" width={26} height={22} />;
    const isQip = qipMap[companySysId] && qipMap[companySysId].toLowerCase() === "yes";

    return (
        <Box sx={{minWidth: '24px', textAlign: 'left', }}>
            { isQip ?
                <Chip size="small" label="QIP" sx={{
                    backgroundColor: orange[100],
                    color: orange[800]           
                }} /> 
                : <EmptySquare/>         
            }
        </Box>
    )

}



interface CsatScoreProps {
    companySysId: string, 
    csatAccum: Record<string, Record<string, number>> | null
}

export function CsatScore({companySysId, csatAccum}: CsatScoreProps) {
    const csatScoreGroups = csatAccum && csatAccum[companySysId]; 
    const Dissatisfied = (csatScoreGroups && csatScoreGroups["Dissatisfied"]) || 0;
    const Satisfied = (csatScoreGroups && csatScoreGroups["Satisfied"]) || 0;
    const Neutral = (csatScoreGroups && csatScoreGroups["Neutral"]) || 0;

    if(!csatAccum) return <Box sx={{display: 'flex', justifyContent: 'start'}}>
        <Skeleton variant="rounded" width={22} height={22} sx={{marginRight: '10px'}}/>
        <Skeleton variant="rounded" width={22} height={22} sx={{marginRight: '10px'}}/>
        <Skeleton variant="rounded" width={22} height={22} sx={{marginRight: '10px'}}/>
    </Box>;

    return (
        <Box sx={{display: 'flex', justifyContent: 'start', alignItems: 'end', pt:"8px"}}>
            <Badge color="success"  badgeContent={Satisfied} max={999} sx={{marginRight: "10px", zIndex: '0'}} >
                <SentimentSatisfiedAltIcon sx={{color: `${Satisfied ? grey[800] : grey[300]}`, fontSize: '22px'}}  />
            </Badge>
            <Badge color="warning" badgeContent={Neutral} max={999} sx={{marginRight: "10px", zIndex: '0'}}>
                <SentimentSatisfiedIcon sx={{color: `${Neutral ? grey[800] : grey[300]}`, fontSize: '22px'}}  />
            </Badge>
            <Badge color="error" badgeContent={Dissatisfied} max={999} sx={{marginRight: "10px", zIndex: '0'}}>
                <SentimentVeryDissatisfiedIcon sx={{color: `${Dissatisfied ? grey[800] : grey[300]}`, fontSize: '22px'}} />
            </Badge>
        </Box>
    )  
}



interface companyPriorityCaseCtProps {
    count: number | null, 
    setCompanyCaseFilterId: (id: string) => void, 
    companySysId: string   
}

export function CompanyPriorityCaseCt({count, setCompanyCaseFilterId, companySysId}: companyPriorityCaseCtProps) {
    return (
        <Box sx={{minWidth: '30px', display: 'flex'}}>
            {
                count === null ? 
                    <Skeleton variant="rounded" width={26} height={22} />  :
                    count === 0 ? <EmptySquare/> : 
                    <Tooltip title={`${count} Priority Cases`} placement="top">
                        <ButtonBase onClick={() => setCompanyCaseFilterId(companySysId)} sx={{p:"1px", borderRadius: '5px'}}>
                            <Box sx={{display: 'flex', justifyContent: 'start', fontSize: '16px'}}>
                                <Box sx={{pl:"2px"}}>{count}</Box>
                                <PriorityHighIcon color="warning" sx={{fontSize: '16px'}} />
                            </Box>
                        </ButtonBase>
                    </Tooltip>
            }
        </Box>
    )
}


interface ExpiringContractCtProps {
    expiringContractCtMap: ExpiringContractCtMapEventual, 
    companySysId: string
}

export function ExpiringContractCt({expiringContractCtMap, companySysId}: ExpiringContractCtProps) {
    if(!expiringContractCtMap) return <Skeleton variant="rounded" width={26} height={22} />;
    const expiringContractCt = expiringContractCtMap[companySysId] || 0;

    return (
        <Box sx={{minWidth: '24px', textAlign: 'left', pl:1 }}>
            { Boolean(expiringContractCt) ?  
                <Chip size="small" label={expiringContractCt} sx={{
                    backgroundColor: orange[100],
                    color: orange[800]           
                }} /> : 
                <EmptySquare/>       
            }
        </Box>
    )
}; 

interface MrrProps {
    companySysId: string, 
    invoiceTotalsMap: InvoiceTotalsEventual
    divisor?: number
}

export function Mrr({companySysId, invoiceTotalsMap, divisor}: MrrProps){
    if(!invoiceTotalsMap) return <Skeleton variant="rounded" width={26} height={22} />; 
    let mrr = parseFloat(invoiceTotalsMap[companySysId]?.mrrTotal) || 0;
    if(divisor) mrr = mrr / divisor

    return (
        <Box sx={{minWidth: '24px', px:1 }}>
            {Math.round(mrr).toLocaleString()}
        </Box>
    )
}

interface ProfitabilityDisplayProps {
    company: CompanyOption, 
    profitability: ProfitabilityEventual
    profitabilityDateStr: string
}

export function ProfitabilityDisplay({company, profitability, profitabilityDateStr}: ProfitabilityDisplayProps) {

    const theme = useTheme(); 

    if(!profitability) return (
        <Box sx={{px:2}}>
            <Skeleton variant="rectangular" sx={{width: '100%'}} height={20} />
        </Box>
    ); 
    const compProfitability = profitability[company.sys_id];
    if(!compProfitability) return <Box>N/A</Box>

    const amTime = parseFloat(compProfitability["GM Account Management Time"]) || 0; ; 
    const rev = parseFloat(compProfitability["GM Invoice Revenue"]) || 0; 
    const prodCost = parseFloat(compProfitability["GM Product Cost"]) || 0; 
    const resCostMonthly = parseFloat(compProfitability["GM Resource Cost Monthly"]) || 0; 
    const stratLeadCost = parseFloat(compProfitability['GM Strategic Lead Cost']) || 0;     

    // Formula from Dashboard: 

        // 	(
		// 		[[GM Invoice Revenue]] - 
		// 			([[GM Product Cost]] + [[GM Account Management Cost]] + [[GM Resource Cost Monthly]])
		// 	) 
		// 	/ [[GM Invoice Revenue]]
		// ) * 100

    // and for Account Management: 

        // [[GM Strategic Lead Cost]] * ([[GM Account Management Time]] / 3600)



    const acMgmtCost = stratLeadCost && amTime ? stratLeadCost * (amTime / 3600) : 0;
    const profit = rev - prodCost - acMgmtCost - resCostMonthly;
    const profitPercent = (profit / rev) * 100;
    const prodCostPercent = (prodCost / rev) * 100;
    const acMgmtCostPercent = (acMgmtCost / rev) * 100;
    const resCostMonthlyPercent = (resCostMonthly / rev) * 100;

    if(rev === 0) return <Box sx={{p:2}}>N/A</Box>


    const tooltipMsg = <Box sx={{display: 'flex', flexDirection: 'column'}}>
        <Box sx={{mb:2, display: 'flex'}}>
            <Box>{`${profitabilityDateStr} Profitiability: ${Math.round(profitPercent)}%`}</Box>
            <Box sx={{flexGrow: 1, minWidth: '20px'}} />
            <Box sx={{ml:1, width: '20px', height: '10px', backgroundColor: theme.palette.success.light, borderRadius: '2px'}} />
        </Box>
        <Box>
            <Box>{`Revenue: $${Math.round(rev).toLocaleString()}%`}</Box>
        </Box>
        <Box sx={{display: 'flex'}}>
            <Box>{`Product Cost: ${Math.round(prodCostPercent)}% ($${Math.round(prodCost).toLocaleString()})%`}</Box>
            <Box sx={{flexGrow: 1, minWidth: '20px'}} />
            <Box sx={{ml:1, width: '20px', height: '10px', backgroundColor: theme.palette.warning.dark, borderRadius: '2px'}} />
        </Box>
        <Box sx={{display: 'flex'}}>
            <Box>{`Account Management Cost: ${Math.round(acMgmtCostPercent)}% ($${Math.round(acMgmtCost).toLocaleString()})%`}</Box>
            <Box sx={{flexGrow: 1, minWidth: '20px'}} />
            <Box sx={{ml:1, width: '20px', height: '10px', backgroundColor: theme.palette.warning.main, borderRadius: '2px'}} />
        </Box>
        <Box sx={{display: 'flex'}}>
            <Box>{`Resource Cost: ${Math.round(resCostMonthlyPercent)}% ($${Math.round(resCostMonthly).toLocaleString()})%`}</Box>
            <Box sx={{flexGrow: 1, minWidth: '20px'}} />
            <Box sx={{ml:1, width: '20px', height: '10px', backgroundColor: theme.palette.warning.light, borderRadius: '2px'}} />
        </Box>
    </Box>

    return (
        <Tooltip title={tooltipMsg} placement="top">
            <Box sx={{px:2}}>
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                    <Box sx={{backgroundColor: theme.palette.success.light, width: `${profitPercent}%`, height: '20px'}}></Box>            
                    <Box sx={{backgroundColor: theme.palette.warning.dark, width: `${prodCostPercent}%`, height: '20px'}}></Box>
                    <Box sx={{backgroundColor: theme.palette.warning.main, width: `${acMgmtCostPercent}%`, height: '20px'}}></Box>
                    <Box sx={{backgroundColor: theme.palette.warning.light, width: `${resCostMonthlyPercent}%`, height: '20px'}}></Box>
                </Box>
            </Box>
        </Tooltip>
    )
}



function EmptySquare(){
    return <Box sx={{color: grey[500], px:1}}>-</Box>
}
