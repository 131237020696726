import { Typography, Box, Skeleton, useTheme, alpha } from "@mui/material";
import { useState } from "react";
import { DashboardColumn, OpsTableColumnTypes, SalesforceOpportunityEdge } from "src/types";
import { DashboardTable } from "./DashboardTable";
import { OpportunityNameDisplay, OpportunityAmountDisplay, OpportunityCompanyDisplay, OpportunityNextStepDisplay} from "./OppsTableCellDisplayFns";
import { Bar, BarChart, Funnel, FunnelChart, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";


interface SalesfunnelProps {
    opportunities: SalesforceOpportunityEdge[] | null; 
}

export function OpportunityClosingDateChart({opportunities}: SalesfunnelProps) {
    const theme = useTheme(); 
    const today = new Date(); 

    const oppMountCount = !opportunities ? {} : opportunities
        .filter(opp => monthDiff(today, new Date(opp.node.CloseDate.value)) < 6 && monthDiff(today, new Date(opp.node.CloseDate.value)) >= 0)      
        .reduce((acc, opp) => {
            const closeDate = opp.node.CloseDate.value; 
            const date = new Date(closeDate);
            const month = date.toLocaleString('default', { month: 'short' }); 
            const year = date.getFullYear();
            const key = year + "-" + month
            if(!acc[key]) acc[key] = 0; 
            acc[key] += 1; 
            return acc; 
        }, {} as Record<string, number>);

    const data = Object.keys(oppMountCount).map(key => {
        return {
            Name: key,
            Count: oppMountCount[key],
        }
    })
    .sort((a, b) => {
        const aDate = new Date(a.Name);
        const bDate = new Date(b.Name);
        return aDate.getTime() - bDate.getTime();
    }); 

    return (
        <Box sx={{height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',pt:1}}>

            {
                !opportunities ? 
                    <Skeleton variant="rectangular" width={250} height={125} sx={{borderRadius: '5px'}} /> : 

                    <>
                    <Typography variant='subtitle1' sx={{textAlign: 'center'}}>Upcoming Closing Dates</Typography>
                    <Box sx={{height: '85%', width: '100%'}}>
                        <ResponsiveContainer>
                            <BarChart
                                data={data}
                                margin={{
                                    right: 10,
                                    left: -30,
                                }}
                                >
                                <XAxis dataKey="Name" />
                                <YAxis />
                                <Tooltip />
                                <Bar dataKey="Count" fill={theme.palette.primary.light} isAnimationActive animationDuration={500} />
                            </BarChart>

                        </ResponsiveContainer>
                    </Box>
                    </>
    

            }


        </Box>

    )
}

function monthDiff(d1: Date, d2: Date) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}


export function Salesfunnel({opportunities}: SalesfunnelProps) {
    const theme = useTheme(); 
    const oppsCount = !opportunities ? {} : opportunities.reduce((acc, opp) => {
        const stage = opp.node.StageName.value; 
        if(!acc[stage]) acc[stage] = 0; 
        acc[stage] += 1; 
        return acc; 
    }, {} as Record<string, number>);

    const data = [
        {
            "value": oppsCount['Pipeline'] || 0,
            "name": "Pipeline",
            "fill": theme.palette.secondary.main
          },
          {
            "value": oppsCount['Upside'] || 0,
            "name": "Upside",
            "fill": theme.palette.secondary.main
          },
          {
            "value": oppsCount['Strong Upside'] || 0,
            "name": "Strong Upside",
            "fill": theme.palette.secondary.main
          },
          {
            "value": oppsCount['Commit'] || 0,
            "name": "Commit",
            "fill": theme.palette.secondary.main
          },
    ]

    return (
        <Box sx={{height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',pt:1}}>

            {
                !opportunities ? 
                    <Skeleton variant="rectangular" width={150} height={125} sx={{borderRadius: '5px'}} /> : 

                    <>
                    <Typography variant='subtitle1' sx={{textAlign: 'center'}}>Opportunity Counts</Typography>
                    <Box sx={{height: '85%', width: '100%'}}>
                        <ResponsiveContainer>
                        <FunnelChart >
                            <Tooltip />
                            <Funnel
                                dataKey="value"
                                data={data}
                                isAnimationActive
                                animationDuration={500}
                            >
                                <LabelList position="end" stroke="none" dataKey="value" content={(props) => <SalesfunnelLabel value={props.value as number} name={props.name as string } y={props.y as number} fill={props.fill as string} />} /> 
                            </Funnel>
                        </FunnelChart>
                        </ResponsiveContainer>
                    </Box>
                    </>
    

            }


        </Box>

    )
}

export function SalesFunnelMrr({opportunities}: SalesfunnelProps) {
    const theme = useTheme(); 
    const mrrSums = !opportunities ? {} : opportunities.reduce((acc, opp) => {
        const stage = opp.node.StageName.value; 
        if(!acc[stage]) acc[stage] = 0; 
        acc[stage] += opp.node.R_MRR__c.value || 0; 
        return acc; 
    }, {} as Record<string, number>);

    const data = [
        {
            "value": mrrSums['Pipeline'] || 0,
            "name": "Pipeline",
            "fill": alpha(theme.palette.info.light, 0.3) 
          },
          {
            "value": mrrSums['Upside'] || 0,
            "name": "Upside",
            "fill": alpha(theme.palette.info.light, 0.3)
          },
          {
            "value": mrrSums['Strong Upside'] || 0,
            "name": "Strong Upside",
            "fill": alpha(theme.palette.info.light, 0.3)
          },
          {
            "value": mrrSums['Commit'] || 0,
            "name": "Commit",
            "fill": alpha(theme.palette.info.light, 0.3)
          },
    ]

    return (
        <Box sx={{height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',pt:1}}>
            {
                !opportunities ? 
                    <Skeleton variant="rectangular" width={150} height={125} sx={{borderRadius: '5px'}} /> : 
                    <>
                    <Typography variant='subtitle1' sx={{textAlign: 'center'}}>MRR</Typography>
                    <Box sx={{height: '85%', width: '100%'}}>
                        <ResponsiveContainer>
                        <FunnelChart >
                            <Tooltip />
                            <Funnel
                                dataKey="value"
                                data={data}
                                isAnimationActive
                                animationDuration={500}
                            >
                                <LabelList position="end" stroke="none" dataKey="value" content={(props) => <SalesfunnelLabel value={props.value as number} name={props.name as string } y={props.y as number} fill={props.fill as string} />} /> 
                            </Funnel>
                        </FunnelChart>
                        </ResponsiveContainer>
                    </Box>
                    </>
            }
        </Box>
    )
}



function SalesfunnelLabel({value, name, y, fill}: {value: number, name: string, y: number, fill: string}) {
//    const theme = useTheme();
//    const color = theme.palette.getContrastText(fill);
    const color = 'black'; 
    const label = name + " - " + Math.round(value).toLocaleString();
    return (
        <text x={'50%'} y={y + 12} fill={color} textAnchor="middle" dominantBaseline="middle">{label}</text>
    )
}





interface OpportunityAmountsProps {
    opportunities: SalesforceOpportunityEdge[] | null; 
    mrrOrNrc: 'mrr' | 'nrc';
}

export function OpportunityAmounts({opportunities, mrrOrNrc}: OpportunityAmountsProps) {
    const theme = useTheme();
    const key = mrrOrNrc === 'mrr' ? 'R_MRR__c' : 'R_NRC__c';
    const label = mrrOrNrc === 'mrr' ? 'MRR' : 'NRC';

    const totalMrr = opportunities?.reduce((acc, opp) => {
        return acc + (opp.node[key].value || 0); 
    }, 0) || 0; 

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py:1}}>
            <Typography variant='subtitle1' sx={{textAlign: 'center'}}>{label}</Typography>
            <Box>
                {
                    opportunities ? 
                        <Typography variant="h5" sx={{textAlign: 'center', color: theme.palette.text.primary}}>${Math.round(totalMrr).toLocaleString()}</Typography> : 
                        <Skeleton variant="rounded" width={30} height={30} />
                }
            </Box>
        </Box>

    )
}

interface OpportunityClosingCtProps {
    opportunities: SalesforceOpportunityEdge[] | null; 
    days: number;
}


export function OpportunityClosingCt({opportunities, days}: OpportunityClosingCtProps) {
    const theme = useTheme();

    const today = new Date(); 
    const closingOpps = opportunities?.filter(opp => {
        const closeDate = new Date(opp.node.CloseDate.value);
        const diff = closeDate.getTime() - today.getTime();
        const daysDiff = diff / (1000 * 3600 * 24);
        return daysDiff < days && daysDiff >= 0;
    });

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py:1}}>
            <Typography variant='subtitle1' sx={{textAlign: 'center'}}>Closing in {`${days} day${days > 1 ? "s" : ""}`}</Typography>
            <Box>
                {
                    opportunities ? 
                        <Typography variant="h5" sx={{textAlign: 'center', color: theme.palette.text.primary}}>{closingOpps?.length || 0}</Typography> : 
                        <Skeleton variant="rounded" width={30} height={30} />
                }
            </Box>
        </Box>

    )
}








interface OpportunityCountProps {
    opportunities: SalesforceOpportunityEdge[] | null; 
}

export function OpportunityCount({opportunities}: OpportunityCountProps) {
    const theme = useTheme();

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', py:1}}>
            <Typography variant='subtitle1' sx={{textAlign: 'center'}}>Opportunities</Typography>
            <Box>
                {
                    opportunities ? 
                        <Typography variant="h5" sx={{textAlign: 'center', color: theme.palette.text.primary}}>{opportunities.length}</Typography> : 
                        <Skeleton variant="rounded" width={30} height={30} />
                }
            </Box>
        </Box>

    )
}







export type SortableOpsTableColumnTypes = 'name' | 'company' | 'mrr' | 'nrc' | 'close date' | 'stage' | 'next step';

interface OpportunityListProps {
    opportunities: SalesforceOpportunityEdge[] | null; 
    hideSquares: Boolean; 
}

export function OpportunityList({opportunities, hideSquares}: OpportunityListProps) {
const [sortBy, setSortBy] = useState<SortableOpsTableColumnTypes | null>('company');
const [sortByDesc, setSortByDesc] = useState<-1 | 1>(1);

// move this to a cookie? 
const columnsToShow: OpsTableColumnTypes[] = ['name', 'company', 'mrr', 'nrc', 'close date', 'stage', 'next step'];

const sortFunctions: Record<SortableOpsTableColumnTypes, (a: SalesforceOpportunityEdge, b: SalesforceOpportunityEdge) => number> = {
    name: (a: SalesforceOpportunityEdge,b: SalesforceOpportunityEdge) => a.node.Name.value?.toLowerCase()  < b.node.Name.value?.toLowerCase() ? -1 * sortByDesc : 1 * sortByDesc, 
    company: (a: SalesforceOpportunityEdge,b: SalesforceOpportunityEdge) => a.node.Account.DisplayValue?.toLowerCase() < b.node.Account.DisplayValue?.toLowerCase() ? -1 * sortByDesc : 1 * sortByDesc, 
    mrr: (a: SalesforceOpportunityEdge,b: SalesforceOpportunityEdge) => a.node.R_MRR__c.value < b.node.R_MRR__c.value ? -1 * sortByDesc : 1 * sortByDesc,
    nrc: (a: SalesforceOpportunityEdge,b: SalesforceOpportunityEdge) => a.node.R_NRC__c.value < b.node.R_NRC__c.value ? -1 * sortByDesc : 1 * sortByDesc,
    'close date': (a: SalesforceOpportunityEdge,b: SalesforceOpportunityEdge) => a.node.CloseDate.value < b.node.CloseDate.value ? -1 * sortByDesc : 1 * sortByDesc,
    stage: (a: SalesforceOpportunityEdge,b: SalesforceOpportunityEdge) => a.node.StageName.value?.toLowerCase() < b.node.StageName.value?.toLowerCase() ? -1 * sortByDesc : 1 * sortByDesc,
    'next step': (a: SalesforceOpportunityEdge,b: SalesforceOpportunityEdge) => nextStepSort(a,b,sortByDesc),
}
const sortableCols = Object.keys(sortFunctions) as OpsTableColumnTypes[]; 

const defaultSortFn = (a: SalesforceOpportunityEdge, b: SalesforceOpportunityEdge) => {
    return a.node.Account.DisplayValue.toLowerCase() < b.node.Account.DisplayValue.toLowerCase() ? -1 * sortByDesc : 1 * sortByDesc;  
};    

const sortFunction = !sortBy ? defaultSortFn : sortFunctions[sortBy];
opportunities?.sort(sortFunction);


const columns: DashboardColumn<OpsTableColumnTypes, SalesforceOpportunityEdge>[] = [
    {name: 'company', label: 'Company', displayFn: (row: SalesforceOpportunityEdge) => <OpportunityCompanyDisplay opportunity={row} />},
    {name: 'name', label: 'Name', nameCol: true, displayFn: (row: SalesforceOpportunityEdge) => <OpportunityNameDisplay opportunity={row}/>},
    {name: 'mrr', label: 'MRR', nameCol: true, colPx: 100, rightAlign: true, displayFn: (row: SalesforceOpportunityEdge) => <OpportunityAmountDisplay opportunity={row} amountVar="R_MRR__c" />},
    {name: 'nrc', label: 'NRC', nameCol: true, colPx: 100, rightAlign: true, displayFn: (row: SalesforceOpportunityEdge) => <OpportunityAmountDisplay opportunity={row} amountVar="R_NRC__c" />},
    {name: 'close date', label: 'Close Date', nameCol: true, colPx: 100, displayFn: (row: SalesforceOpportunityEdge) => new Date(row.node.CloseDate.value).toLocaleDateString()},
    {name: 'stage', label: 'Stage', nameCol: true, colPx: 100, displayFn: (row: SalesforceOpportunityEdge) => row.node.StageName.value},
    {name: 'next step', label: 'Next Step', nameCol: true, displayFn: (row: SalesforceOpportunityEdge) => <OpportunityNextStepDisplay opportunity={row} />},
]; 

if((opportunities && !opportunities.length) || hideSquares) return <Typography variant='caption' sx={{ml:"2px"}}>No results.</Typography>;
return (
    <DashboardTable<OpsTableColumnTypes, SortableOpsTableColumnTypes, SalesforceOpportunityEdge> companiesLoaded={Boolean(opportunities)} columns={columns} orderBy={sortBy} sortableColumns={sortableCols} rows={opportunities} 
        setOrderBy={setSortBy} orderByDesc={sortByDesc} setOrderByDesc={setSortByDesc} columnsToShow={columnsToShow}
        maxHeightPx={500}/>
);

}




function nextStepSort(a: SalesforceOpportunityEdge,b: SalesforceOpportunityEdge, sortByDesc: -1 | 1){
    let aVal = a.node.NextStep?.value?.toLowerCase() || ""; 
    let bVal = b.node.NextStep?.value?.toLowerCase() || "";
    return aVal < bVal ? -1 * sortByDesc : 1 * sortByDesc;
}
