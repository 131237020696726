import * as React from 'react';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack'; 
import Typography from '@mui/material/Typography';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import CompanyBadges from './CompanyBadges';
import { Company } from 'src/types';
import { Button, ButtonBase, Modal, Dialog, useTheme, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, InputLabel, Select, MenuItem, Skeleton, IconButton } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Locations from './Locations';
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import NotCoveredTasks from './NotCoveredTasks';
import SecurityRetainer from './SecurityRetainer';
import { PointerEvent } from "react";
import { useLocations } from 'src/hooks/useLocations';
import assertively from 'src/util/assertively';


export type AboutInfoProps = {
  company: Company
  mapModalRef: React.Ref<HTMLElement>
  //locations: CompanyLocations
}

export default function AboutInfo({company, mapModalRef}: AboutInfoProps) {
  const theme = useTheme(); 

  const [modalOpen, setModalOpen] = useState(false);
  const [cemModalOpen, setCemModalOpen] = useState(false);
  const [securityRetainerPct, setSecurityRetainerPct] = useState<number | null>(null);
  
  const [selectedCemLocation, setSelectedCemLocation] = useState<string | null>(null);
  const locations = assertively(useLocations(company.sys_id));

  const handleOpen = () => setModalOpen(true); 
  const handleClose = () => setModalOpen(false); 

  const handleDialogOpen = () => setCemModalOpen(true);
  const handleDialogClose = () => setCemModalOpen(false);

  if (!selectedCemLocation && locations && locations.length > 0){
    const firstLocation = locations.find(location => location.primary === "true");
    if (firstLocation){
      setSelectedCemLocation(firstLocation.sys_id);
    }
    
  }

  const selectedCemName = locations?.find(location => location.sys_id === selectedCemLocation)?.u_cem;
  const selectedCemLocationName = locations?.find(location => location.sys_id === selectedCemLocation)?.name;

  const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    maxWidth: 1000, 
    height: '70%', 
    maxHeight: 1000,
    bgcolor: 'background.paper',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 1,
    boxShadow: 24,
    p: 2,
  };
  

  return (
    <Stack sx={{ width: "100%", minWidth: "300px" }}>
      <Typography variant="h5" align="center">
        {company.name}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <CompanyBadges company={company} justifyContentSetting="center" />
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "center", width: "100%", mt: 1 }}
      >
        <Button
          size="small"
          variant="contained"
          onClick={handleOpen}
          sx={{ mx: 1 }}
        >
          See Locations{" "}
          <LocationOnIcon sx={{ fontSize: "16px", ml: 1, pb: 0.5 }} />
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 3,
        }}
      >
        <Entry heading="Status" value={company.u_status.toLowerCase() === 'new' ? "Onboarding" : company.u_status} />
        <Entry heading="Primary Vertical" value={company.u_vertical} />
        <Entry
          heading="Secondary Vertical"
          value={company.u_secondary_vertical}
        />
        <Entry
          heading="Originating Entity"
          value={company.u_originating_entity}
        />

        <Entry
          
          heading="POC"
          value={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ mr: 0.5 }}>{company.u_poc.display}</Box>
              {company.u_poc.email ? (
                <ClipboardWrapper textToCopy={company.u_poc.email}>
                  <EmailIcon sx={{ fontSize: "medium", width: "22px" }} />
                </ClipboardWrapper>
              ) : (
                <Box sx={{ width: "22px" }} />
              )}
              {company.u_poc.phone || company.u_poc.mobile_phone ? (
                <ClipboardWrapper
                  textToCopy={company.u_poc.phone || company.u_poc.mobile_phone}
                >
                  <PhoneIcon sx={{ fontSize: "medium", width: "22px" }} />
                </ClipboardWrapper>
              ) : (
                <Box sx={{ width: "22px" }} />
              )}
            </Box>
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 3
        }}
      >
        <Entry
          heading="Account Owner"
          value={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ mr: 0.5 }}>{company.u_ae.display}</Box>
              {company.u_ae.email ? (
                <ClipboardWrapper textToCopy={company.u_ae.email}>
                  <EmailIcon sx={{ fontSize: "medium", width: "22px" }} />
                </ClipboardWrapper>
              ) : (
                <Box sx={{ width: "22px" }} />
              )}
              {company.u_ae.phone || company.u_ae.mobile_phone ? (
                <ClipboardWrapper
                  textToCopy={company.u_ae.phone || company.u_ae.mobile_phone}
                >
                  <PhoneIcon sx={{ fontSize: "medium", width: "22px" }} />
                </ClipboardWrapper>
              ) : (
                <Box sx={{ width: "22px" }} />
              )}
            </Box>
          }
        />
        <Entry
          heading={`CSM ${company.u_cem_type === "pool" ? "(Pool)" : ""}`}
          value={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              {company.u_cem_type === "pool" ? (
                <Box>{company.u_cem_pool.display}</Box>
              ) : (
                <>
                  <Box sx={{ mr: 0.5 }}>{company.u_cem.display}</Box>
                  {company.u_cem_type !== "pool" && company.u_cem.email ? (
                    <ClipboardWrapper textToCopy={company.u_cem.email}>
                      <EmailIcon sx={{ fontSize: "medium", width: "22px" }} />
                    </ClipboardWrapper>
                  ) : (
                    <Box sx={{ width: "22px" }} />
                  )}
                  {company.u_cem_type !== "pool" &&
                  (company.u_cem.phone || company.u_cem.mobile_phone) ? (
                    <ClipboardWrapper
                      textToCopy={
                        company.u_cem.phone || company.u_cem.mobile_phone
                      }
                    >
                      <PhoneIcon sx={{ fontSize: "medium", width: "22px" }} />
                    </ClipboardWrapper>
                  ) : (
                    <Box sx={{ width: "22px" }} />
                  )}
                </>
              )}
            </Box>
          }
        />
        {company.u_profile2.display === "Enterprise - Multisite" && (
          
          <Entry
          heading={
            <Box sx={{maxWidth: '170px'}}>
              <Box>
                <Box component="span" sx={{mr:1}}>
                  CSM for:  
                </Box>
                <IconButton onClick={handleDialogOpen} sx={{p:.5}}>
                  <LocationOnIcon
                    sx={{ fontSize: "16px", color: 'black' }}
                  />
                </IconButton>
              </Box>
              {
                selectedCemLocation ? 
                <Box sx={{ml:.5}}>
                    {selectedCemLocationName}
                </Box> : 
                <Skeleton variant="rectangular" height={16} width={120} sx={{borderRadius: '5px'}}/>
              }
            </Box>
          }
          value={
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Box sx={{ mr: 0.5 }}>{selectedCemName?.name}</Box>
              {selectedCemName?.email ? (
                <ClipboardWrapper textToCopy={selectedCemName.email}>
                  <EmailIcon sx={{ fontSize: "medium", width: "22px" }} />
                </ClipboardWrapper>
              ) : (
                <Box sx={{ width: "22px" }} />
              )}
              {selectedCemName?.mobile_phone ? (
                <ClipboardWrapper textToCopy={selectedCemName.mobile_phone}>
                  <PhoneIcon sx={{ fontSize: "medium", width: "22px" }} />
                </ClipboardWrapper>
              ) : (
                <Box sx={{ width: "22px" }} />
              )}
            </Box>
          }
        />        
        )}
        <Entry heading="Strategic Lead" value={
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Box sx={{mr:.5}}>{company.u_spc.display}</Box>
            {
              company.u_spc.email ? 
                <ClipboardWrapper textToCopy={company.u_spc.email}><EmailIcon sx={{fontSize: 'medium', width: '22px'}}/></ClipboardWrapper> : <Box sx={{width: '22px'}} />
            }
            {
              company.u_spc.phone || company.u_spc.mobile_phone ? 
                <ClipboardWrapper textToCopy={company.u_spc.phone || company.u_spc.mobile_phone}><PhoneIcon sx={{fontSize: 'medium', width: '22px'}}/></ClipboardWrapper> : <Box sx={{width: '22px'}} />
            }
          </Box>
        }/>
        <Entry heading="Security Lead" value={
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Box sx={{mr:.5}}>{company.u_security_lead.display}</Box>
            {
              company.u_security_lead.email ? 
                <ClipboardWrapper textToCopy={company.u_security_lead.email}><EmailIcon sx={{fontSize: 'medium', width: '22px'}}/></ClipboardWrapper> : <Box sx={{width: '22px'}} />
            }
            {
              company.u_security_lead.phone || company.u_security_lead.mobile_phone ? 
                <ClipboardWrapper textToCopy={company.u_security_lead.phone || company.u_security_lead.mobile_phone}><PhoneIcon sx={{fontSize: 'medium', width: '22px'}}/></ClipboardWrapper> : <Box sx={{width: '22px'}} />
            }
          </Box>
        }/>
        <Entry heading="Enterprise Lead" value={
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Box sx={{mr:.5}}>{company.u_enterprise_lead.display}</Box>
            {
              company.u_enterprise_lead.email ? 
                <ClipboardWrapper textToCopy={company.u_enterprise_lead.email}><EmailIcon sx={{fontSize: 'medium', width: '22px'}}/></ClipboardWrapper> : <Box sx={{width: '22px'}} />
            }
            {
              company.u_enterprise_lead.phone || company.u_enterprise_lead.mobile_phone ? 
                <ClipboardWrapper textToCopy={company.u_enterprise_lead.phone || company.u_enterprise_lead.mobile_phone}><PhoneIcon sx={{fontSize: 'medium', width: '22px'}}/></ClipboardWrapper> : <Box sx={{width: '22px'}} />
            }
          </Box>
        }/>


        <Entry heading="Support Pod" value={company.u_support_group.display}/>
      </Box>

      <Grid container sx={{ mt: 3 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box sx={{ fontSize: "1.6rem" }}>{`Security Retainer${
            securityRetainerPct || securityRetainerPct === 0
              ? ": " + securityRetainerPct + "%"
              : ""
          }`}</Box>
          <SecurityRetainer
            companyId={company.sys_id}
            setSecurityRetainerPct={setSecurityRetainerPct}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 3 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            mx: 1,
          }}
        >
          <NotCoveredTasks />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 3, mb: 2 }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            mx: 3,
          }}
        >
          <Box sx={{ my: 1 }}>
            <Typography>Portal Users</Typography>
          </Box>
          <PortalUsageCharts company={company} />
        </Grid>
      </Grid>

      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="location-modal-title"
        aria-describedby="location-modal-description"
      >
        <Box sx={modalStyle} ref={mapModalRef}>
          <Locations accountSysId={company.sys_id} />
        </Box>
      </Modal>

      <Dialog
        open={cemModalOpen}
        onClose={handleDialogClose}
        aria-labelledby="cem-modal-title"
        aria-describedby="cem-modal-description"
      >
        <DialogTitle>Select a Location</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ pb: 2 }}></DialogContentText>

          <FormControl sx={{minWidth: '300px'}} fullWidth>
            <InputLabel id="select-cem-from-location">Location</InputLabel>
            <Select
              labelId="select-cem-from-location"
              id="select-cem-from-location-dropdown"
              value={selectedCemLocation}
              label="Location"
              onChange={(e) => setSelectedCemLocation(e.target.value as string)}
            >
              {/* <MenuItem value={10}>Ten</MenuItem> */}

              {locations?.map((location) => (
                <MenuItem key={location.sys_id} value={location.sys_id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{my:3, wdith: '100%', textAlign: 'center', fontSize: '1.5rem'}}>
            Location CSM: {selectedCemName?.name}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}

interface EntryProps {
  heading: React.ReactNode;  
  value: React.ReactNode; 
}

function Entry({heading, value}: EntryProps){
  return (
      <Box sx={{width: '100%', fontSize: '1.5rem', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', mt:.5, px:1}}>
          <Box>{heading}</Box>
          <Box sx={{alignText: 'left'}}>
              <Box sx={{display: 'flex', justifyContent: 'center', fontWeight: 'bold',}}>{value}</Box>
          </Box>
      </Box>
  )
}

interface PortalUsageProps {
  company: Company
}

function PortalUsageCharts({company}: PortalUsageProps){

  if(company.activeContactsCt === 0) return <>"No active contacts in ServiceNow."</>
  const {activeContactsCt, portalUserCt, portalUserLast30DaysCt, portalUserLast7DaysCt} = company; 
  const portalUserPct = Math.round(portalUserCt / activeContactsCt * 100); 
  const last30DaysPct = Math.round(portalUserLast30DaysCt / activeContactsCt * 100); 
  const last7DaysPct = Math.round(portalUserLast7DaysCt / activeContactsCt * 100); 

  const chartsNeeded = [
    {label: "All Time", value: portalUserPct, tooltip: `${portalUserCt} of ${activeContactsCt} active contacts in ServiceNow have used the portal.`}, 
    {label: "Last 30 Days", value: last30DaysPct, tooltip: `${portalUserLast30DaysCt} of ${activeContactsCt} active contacts in ServiceNow have used the portal in the last 30 days.`}, 
    {label: "Last 7 Days", value: last7DaysPct, tooltip: `${portalUserLast7DaysCt} of ${activeContactsCt} active contacts in ServiceNow have used the portal in the last 7 days.`}
  ]; 

  return(
    <>
      {chartsNeeded.map(el => {
        return(
          <Box key={`portal-usage-${el.label}`} sx={{width: '100%', display: 'flex', alignItems: 'end'}}>
            <Box sx={{mx:2, width: '100px'}}><Typography>{el.label}</Typography></Box>
            <Box sx={{flexGrow: 1}}>
              <LinearProgressWithLabel value={el.value} tooltipText={el.tooltip}/>
            </Box>
          </Box>
  
        )
      })}
    </>
  )
}


function LinearProgressWithLabel(props: LinearProgressProps & { value: number, tooltipText:string }) {
  const [val,setVal] = useState(0); 

  useEffect(()=>{
    setTimeout(()=>{
      setVal(props.value)
    },50)  
  }, [props.value])


  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} value={val} />
      </Box>
      <Tooltip title={<Box sx={{textAlign: 'center'}}>{props.tooltipText}</Box>} placement='top'>
        <Box sx={{ minWidth: 35, cursor:'pointer' }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value,
          )}%`}</Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}



interface ClipboardWrapperProps {
  children: React.ReactNode;
  textToCopy: string;
}

export function ClipboardWrapper({ children, textToCopy }: ClipboardWrapperProps) {
  const [text, setText] = useState("");
  const [showTooltip, setShowTooltip] = useState(false);

  const desktopHoverMessage = `Click to copy`;
  const desktopClickMessage = `Copied to clipboard`;
  const mobileFirstTapMsg = `Tap again to copy`;
  const mobileSecondTapMsg = `Copied to clipboard`;


  const handleClose = () => {
      setShowTooltip(false)
  }

  const handleOpen = () => {
      setShowTooltip(true);
      setText(desktopHoverMessage)
  }

  const onChildWrapperClick = (e: PointerEvent<Element>) => {
      const pointerType = e.nativeEvent.pointerType;
      if (pointerType === "mouse") {
          navigator.clipboard.writeText(textToCopy);
          setShowTooltip(true);
          setText(desktopClickMessage);
      } else {
          if (!showTooltip) {
              setShowTooltip(true);
              setText(mobileFirstTapMsg);
          } else {
              navigator.clipboard.writeText(textToCopy);
              setText(mobileSecondTapMsg);
          }
      }
  }


  return (
      <Tooltip title={
          <Box style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>
              <Box sx={{fontSize: 16, my:1}}>{textToCopy}</Box>
              <Box sx={{mt:2, mb:1}}>{text}</Box>
          </Box>
      } open={showTooltip} onClose={handleClose} onOpen={handleOpen} placement="top" leaveTouchDelay={3000}>
          <ButtonBase onClick={(e) => { onChildWrapperClick((e as unknown as PointerEvent)) }} sx={{ display: 'flex', justifyContent: 'center', cursor: 'pointer', borderRadius: 1 }} >
              {children}
          </ButtonBase>
      </Tooltip>
  )
}